import React, { useState, useContext } from 'react'
import { Row, Col, Card, Form, Input, Button, Alert } from 'antd'
import { withRouter } from 'react-router-dom'
import './login.css'
// import logo from '../../brand/ec.jpg'

// Contexts
import { AuthContext } from "../../contexts/AuthContext"
const Login = withRouter(({history}) => {

   const Auth = useContext(AuthContext)

   const [username, setUsername] = useState('')
   const [password, setPassword] = useState('')
   const [usernameMsg, setUsernameMsg] = useState('')
   const [passwordMsg, setPasswordMsg] = useState('')
   const [alert, setAlert] = useState('')
   const [submit, setSubmit] = useState(false)

   const handleSubmit = async () => {
      
      document.title = "Signing in..."

      setSubmit(true)
      
      setAlert('')
      if (username !== '' && password !== '') {
         
         try {
            
            await Auth.authenticateUser( {userName: username, password})
            history.push("/")
         } catch (error) {
            console.log(error)
            setAlert(error.response.data.msg)
         }

         setSubmit(false)
      } else {
         
         if (username === '') {
         
            setUsernameMsg("Username is required")
         } else {

            setUsernameMsg("")
         }
         if (password === '') {
            
            setPasswordMsg("Password is required")
         } else {
            
            setPasswordMsg("")
         }

      }
   }
   return (
      <section className="login">
         <Row>
            <Col className="d-flex justify-content-center align-items-center">
               <Card style={{width: '500px'}} size="small" className="shadow p-4">
                  <div className="d-flex justify-content-center align-items-center">
                   
                     {/* <img src={logo} height="60" width="70" alt="logo" />  */}
                     <h1 className="login-title mb-0">exactboard Online</h1>
                  </div>
                  {
                     alert? 
                     
                     <Alert
                        className="my-3"
                        message="Error"
                        description={alert}
                        type="error"
                        showIcon
                     />
                     :''
                  }
                  <Form
                     name="normal_login"
                     className="login-form mt-3"
                  >
                     <h5 className="mb-0">Username</h5>
                     <Form.Item
                        className="mb-2"
                        name="username"
                        required
                        hasFeedback={submit && username === ''? true: false}
                        validateStatus={submit && username === ''? "error": ""}
                        help={submit && username === ''? usernameMsg: ""}
                     >
                        <Input onChange={ (e) => setUsername(e.currentTarget.value) } />
                     </Form.Item>
                     <h5 className="mb-0">Password</h5>
                     <Form.Item
                        colon={false}
                        name="password"
                        required
                        hasFeedback={submit && password === ''? true: false}
                        validateStatus={submit && password === ''? "error": ""}
                        help={submit && password === ''? passwordMsg: ""}
                     >
                        <Input.Password onChange={ (e) => setPassword(e.currentTarget.value) } />
                     </Form.Item>
         
                     <Form.Item>
                        <Button onClick={handleSubmit} block style={{backgroundColor: "#bd1b27", color: 'white', border: 'none', outline: 'none'}} htmlType="submit">
                           Login
                        </Button>
                     </Form.Item>
                  </Form>
               </Card>
            </Col>
         </Row>
      </section>
   )
})

export default Login
