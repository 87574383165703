
export const DashboardReducer = (state, action) => {

   switch (action.type) {

      case 'SET': 

         return {

            ...state,
            [action.key]: action.payload
         }

      case 'ADD': 

         return {

            ...state,
            [action.key]: [...state[action.key], action.payload]
         }

      case 'DELETE': 

         const _state = state.dashboards.filter(_d => _d.id === action.payload)
         console.log(_state)
         return {

            ...state,
            [action.key]: _state
         }

     
      default:
         return state
   }
}