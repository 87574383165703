import React, { useContext } from 'react'
import { AuthContext } from './contexts/AuthContext'
import Login from './views/login/Login'
import {
   Route,
   Redirect
 } from "react-router-dom"
 
const SessionRouter = ({ children, ...rest }) => {
   
   const Auth = useContext(AuthContext)

   const getLoginComponent = () => {
      return (
         <Login />
      )
   }
   
   return (
      <Route {...rest}>
         {  
            (() => {

               if (!Auth.isOnline) {

                  return getLoginComponent()

               } else if(Auth.isOnline && rest.path === "/login") {

                  return <Redirect to={{  pathname: "/" } } />

               }  else {

                  return children
               }

            })()
         }
      </Route>
   )
}

export default SessionRouter
