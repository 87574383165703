import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Card, Avatar, Upload } from 'antd'
import { serialize, deserialize } from 'bson'
import { saveAs } from 'file-saver'
import {
   PieChartOutlined,
   BarChartOutlined,
   LineChartOutlined,
   AreaChartOutlined,
   FileExcelOutlined, 
   DropboxOutlined
} from '@ant-design/icons'

import { withRouter } from 'react-router-dom'

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

// Contexts
import { DashboardContext } from "../../contexts/DashboardContext"
// Components
import Board from './Board'
// animations
// import { AnimateGroup } from '../animations/Animate'
// import XLSX from 'xlsx'
import './main.css'
// images

const { Dragger } = Upload

const Dashboard =  withRouter(({ history }) => {

   const { dashboards, dispatch: dashboardDispatch } = useContext(DashboardContext)

   const [localDashboards, setLocalDashboards] = useState([])

   const [addingNewChart, setAddingNewChart] = useState(false)

   useEffect(() => {

      const _dshb = JSON.parse(localStorage.getItem('dashboards'))
      
      if(_dshb) {

         setLocalDashboards([..._dshb])
      }

   }, [])

   const reorder = (list, startIndex, endIndex) => {

      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
    
      return result
   }

   const reOrderFromUna = (list, obj, endIndex) => {

      const result = Array.from(list)
      result.splice(endIndex, 0, obj)
      
      return result
   }

   const onDragEnd = (result) => {

      // dropped outside the list
      if (!result.destination || result.destination.droppableId === 'una' || (result.destination.index === result.source.index && result.source.droppableId === 'pangalawa')) {
        return
      }

       if(localDashboards.length > 0) {

         setAddingNewChart(true)
      }


      if(result.source.droppableId === 'una') {
         // Item/board to be inserted in for chart
         

         const _object = dashboards[result.source.index]
         
         setLocalDashboards(prevState => {

            _object.lockDrag = false

            if(result.destination.index === 0) {

               const items = [ _object, ...prevState ]

               localStorage.setItem('dashboards', JSON.stringify(items))

               return items

            } else {
               
               prevState = [ ...prevState ]

               const items = reOrderFromUna(
                  prevState,
                  _object,
                  result.destination.index
               )
               
               localStorage.setItem('dashboards', JSON.stringify(items))

               return items
            }
   
         })

      } else {

         const items = reorder(
            localDashboards,
            result.source.index,
            result.destination.index
         )

         
         setLocalDashboards(items)

         localStorage.setItem('dashboards', JSON.stringify(items))
      }

      if(localDashboards.length > 0) {

         setTimeout(() => {
   
            setAddingNewChart(false)
         }, 1280)
         
      }
      
  
   }

   const getIcon = (type) => {

      switch(type) {

         case 'pie':
            return <PieChartOutlined />

         case 'bar':
            return <BarChartOutlined />

         case 'line':
            return <LineChartOutlined />

         case 'area':
            return <AreaChartOutlined />

         case 'combo':
            return <LineChartOutlined />

         default:
            return type
      }
   }
  
   const removeChart = (id) => {

      if(localDashboards.length > 1) {
         
         setAddingNewChart(true)
      }

      setLocalDashboards(prevState => {

         const _db = prevState.filter(_ps => _ps._id !== id)

         
         localStorage.setItem('dashboards', JSON.stringify(_db))

         return _db
      })

      if(localDashboards.length > 1) {

         setTimeout(() => {
   
            setAddingNewChart(false)
         }, 1280)
         
      }

   }

   const fileBeforeLoad = React.useCallback((file) => {

      // const datas =serialize(doc[0])

      // De serialize it again
      // const doc_2 = JSON.stringify(deserialize(datas))

      // const blob = new Blob([doc_2], {type: "application/json;charset=utf-8"})
      // saveAs(blob, "test.json")

      // for (const _d of datas) {
         
      // dashboardDispatch({type: 'ADD', key: 'dashboards', payload: doc_2 })
      // }
         

      const reader = new FileReader()

      reader.onload = (e) => {

         const data = JSON.parse(e.target.result)
         
         for (const _d of data) {
            
            dashboardDispatch({type: 'ADD', key: 'dashboards', payload: _d })
         }
      }
      
      reader.readAsText(file)

      
      return false
   },[dashboardDispatch])

   return (
   
      <Row className="p-3">
         <DragDropContext onDragEnd={onDragEnd}>
            <Col span={3}>
               <Row className="mb-3">
                  <Col span={24}>
                     <Card className="shadow-sm text-center">
                        <Dragger accept=".xlsx, .csv, .json" showUploadList={false} beforeUpload={(file) => fileBeforeLoad(file)}  name="files">
                           <p className="ant-upload-drag-icon mb-1">
                              {/* <Avatar className="shadow-sm" size={64} style={{ backgroundColor:'rgb(103 218 144)' }}>
                                 <div style={{ color: 'white' }}>
                                 </div>
                              </Avatar> */}
                              <FileExcelOutlined />
                           </p>
                           Import File
                        </Dragger>
                        
                     </Card>
                  </Col>
               </Row>
               <Row style={{ maxHeight: '800px', overflow: 'auto' }}>
                  <Col span={24}>
                     <Card className="shadow">
                        <Droppable droppableId="una">
                           {(provided, snapshot) => (
                              <div
                                 {...provided.droppableProps}
                                 ref={provided.innerRef}
                              >
                              {
                                 dashboards.length === 0? '': 
                                    dashboards.map((item, _i) => 
                                       {

                                          const _index = localDashboards.findIndex(_ld => item._id === _ld._id)
                                       
                                          return ( 

                                             <Draggable isDragDisabled={_index > -1? true : false} key={`_xb${item._id}`} draggableId={`_xb${item._id}`} index={_i}>
                                                {
                                                   (provided, snapshot) => (
                                                      <div
                                                         ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                      >
                                                         <div style={{ userSelect: 'none' }} key={`_xb${item._id}`} title={item.type} className="text-center mb-2">
                                                            <Avatar className="shadow-sm" size={64} style={{ backgroundColor: _index > -1 ? 'rgb(103 218 144)':'rgb(150 162 247)' }}>
                                                               <h1 style={{ color: 'white' }}>
                                                                  { getIcon(item.type) }
                                                               </h1>
                                                            </Avatar>
                                                            <h4> { item.name } </h4>
                                                         </div>
                                                      </div>
                                                   )
                                                }
                                             </Draggable>
                                          )
                                       })
                              } 
                              
                              {provided.placeholder}

                              </div>
                           )}
                        </Droppable>
                              
                     </Card>
                  </Col>
               </Row>
            </Col>

            <Col className="pl-3" span={21}>
                  <Droppable droppableId="pangalawa">
                     {(provided, snapshot) => (
                        <div
                           {...provided.droppableProps}
                           ref={provided.innerRef}
                        >
                        {
                           localDashboards.length === 0? 
                              <Row style={{ marginTop: '230px' }}>
                                 <Col span={24}>
                                    <div className="text-center">
                                       <p style={{ fontSize: '50px', margin: 0 }}>

                                          <DropboxOutlined />
                                       </p>
                                       <h1> Drop Here </h1>  
                                    </div>
                                 </Col>
                              </Row>
                              : 
                              !addingNewChart?
                                 localDashboards.map((item, _i) =>  (
                                 
                                    <Board removeChart={removeChart} key={_i} chartData={item} index={_i} />
                                             
                                 )) : 
                                 
                                 <Row style={{ marginTop: '230px' }}>
                                    <Col span={24}>
                                       <div className="d-flex justify-content-center align-items-center">
                                          <div className="breeding-rhombus-spinner">
                                             <div className="rhombus child-1"></div>
                                             <div className="rhombus child-2"></div>
                                             <div className="rhombus child-3"></div>
                                             <div className="rhombus child-4"></div>
                                             <div className="rhombus child-5"></div>
                                             <div className="rhombus child-6"></div>
                                             <div className="rhombus child-7"></div>
                                             <div className="rhombus child-8"></div>
                                             <div className="rhombus big"></div>
                                          </div>
                                       </div>
                                    </Col>
                                 </Row>
                                
                        }
                        {provided.placeholder}
                        </div>
                     )}
                  </Droppable>
               
            </Col>
         </DragDropContext>
      </Row>
   )
})

export default Dashboard
