import React, { useContext, useEffect } from 'react'
import { Skeleton, Row, Col, Card } from 'antd'
import './main.css'
/**
 * @import Components
 */

 import Dashboard from '../../components/main/Dashboard'
import { DashboardContext } from "../../contexts/DashboardContext"
import { Animator } from '../../components/animations/Animate'
 /**
 * @import Contexts
 */


const Main = () => {

   useEffect(() => {
      document.title = "Dashboard"
   }, [])
   
   const { isFetching } = useContext(DashboardContext)
   return (
     
         isFetching?
         <section className="my-2 mx-2">
            <Card>

               <Row>
                  <Col>
                     <Skeleton active />
                  </Col>
               </Row>
            </Card>
         </section>
         :
         (
            <Animator
               onState={!isFetching}
               timeout={300}
               type="slide"
            >
            
               <Dashboard />
            </Animator>
         )
   )
}

export default Main
