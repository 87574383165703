import React, { useReducer, useEffect } from 'react'
import { DashboardReducer } from '../reducers/DashboardReducer'
import axios from 'axios'

const initialState = {

   dashboards: [],
   isFetching: true
   
}

export const DashboardContext = React.createContext()

export const DashboardProvider = props => {

   const [state, dispatch] = useReducer(DashboardReducer, initialState)
   useEffect(() => {
      ( async () => {

         if(state.dashboards.length === 0) {
            
            const [_dashboards] = await Promise.all([ axios.get('/api/dashboards')])
             
            dispatch({type: 'SET', key:'dashboards', payload: _dashboards.data })
            dispatch({type: 'SET', key:'isFetching', payload: false })
            
         }
      })()

   }, [state.dashboards.length])
   return (
      
      <DashboardContext.Provider value={{ 
         ...state,
         dispatch
      }}>

         { props.children }
      </DashboardContext.Provider>
   )
}
