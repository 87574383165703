import React, { useContext } from 'react'
// import logo from  '../../brand/ec.jpg'
import { Layout, Menu, Dropdown } from 'antd'
import { withRouter } from 'react-router-dom'
import { CaretDownOutlined } from '@ant-design/icons'
// Contexts
import { AuthContext } from "../../contexts/AuthContext"

const Header = withRouter(({ history, sidebarCollapsed, setSBCollapsed }) => {

   const { Header } = Layout
   const Auth = useContext(AuthContext)

   const logout = () => {

      localStorage.removeItem("techVoiceToken")

      Auth.dispatch({ type: 'LOGOUT' })
      history.push("/login")
   }

   const menu = () => (
      <Menu>
         <Menu.Item key="0">
            <span onClick={logout}>Logout</span>
         </Menu.Item>
        
      </Menu>
   )

   return (
      <Header className="py-0 px-2 d-flex align-items-center" style={{ background: '#fff', height: '42px'}}>
                           
         {/* <img height="35" src={logo} alt="" className="mr-2" /> */}
         <h4 className="ml-2 m-0">exactboard Online</h4>

         <div className="flex-fill d-flex justify-content-end align-items-center mr-4" style={{height: '100&'}}>
            <Dropdown overlayClassName="dp-overlay" overlay={menu} trigger={['click']}>
               <span style={{cursor: 'pointer'}} className="ant-dropdown-link" >
                  {Auth.user.name} <CaretDownOutlined />
               </span>
            </Dropdown>
         </div>
      </Header>
   )
})

export default Header
