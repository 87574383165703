import React from 'react'
import Avatar from './Avatar'
import './style.css'

const Loading = () => {
   return (
      <div className="loading-banner">
         <Avatar />
         <p className="mt-3">

            Loading Resource
         </p>
      </div>
   )
}

export default Loading