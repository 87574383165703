
export const CSVReducer = (state, action) => {

   switch (action.type) {

      case 'SET': 

         return {

            ...state,
            [action.key]: [...state[action.key], action.payload]
         }

      case 'UPDATE': 

         return {

            ...state,
            [action.key]: [...action.payload]
         }

      default:
         return state
   }
}