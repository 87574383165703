import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts"
import { 
   Card,
   Col,
   Row,
   Table,
} from 'antd'
import { v4 as uuidv4 } from 'uuid'
import {
   LockOutlined, UnlockOutlined
 } from '@ant-design/icons'

import { Draggable } from "react-beautiful-dnd"

import ExtraOptions from './ExtraOptions'

const Board = React.memo(({ chartData, index, removeChart }) => {
      /**
    * @Object
    * desc: Supply object same structure as below 
    * {
         name: 'Net Profit',
         data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }
    */

   const [seriesData, setSeriesDate] = useState([]) 
   const [options, setOptions] = useState({}) 

   
   const [tableColumns, setTableColumns] = useState([]) 
   const [tableData, setTableData] = useState([]) 

   const [showTable, setTableVisibility] = useState(false)

   const [isLock, setLockState] = useState(false)

   useEffect(() => {

      const getWidthLayout = () => {

         const _w = chartData.series.map((_s) => {
   
            if(_s.type === 'bar') {
   
               return 0
            } else {
   
               return 2
            }
         })
   
         return _w
      }

      if(chartData.type === 'pie') {
         
         setSeriesDate([...chartData.series[0].data])
      } else {
          
         setSeriesDate([...chartData.series])

         
         // Table data building

         let columnsa = [{

            title: '2020',
            dataIndex: `_xbTable`,
            key: '_xbTable',
            fixed: 'left',
            width: 250,
            align: 'left'
         }]

         let datas = []
         
         for (const _cv of chartData.labels) {

            columnsa = [
               ...columnsa, 
               {
                  title: _cv,
                  dataIndex: `_xb${_cv.replace(/[{()-.,' '}]/g,'').toLowerCase()}`,
                  width: 150,
                  align: 'center'
               }
            ]
            
         }

         
         for (const _sv of chartData.series) {
            
            
            let i = 0
            let _data = {}

            for (const _sd of _sv.data) {
               

               _data[`_xb${chartData.labels[i].replace(/[{()-.,' '}]/g,'').toLowerCase()}`] = _sd
   
               i++
            }

            _data[`_xbTable`] = _sv.name
            datas = [...datas, _data]
            
         }

         for (const _ds of datas) {
            _ds.key = `_xb-${uuidv4()}`
            
         }
         
         setTableColumns([...columnsa])
         setTableData([...datas])
      }

      /**
       * @object
       * 
       * Arrange data for chart
       */

      switch (chartData.type) {

         case 'bar':

            setOptions(prevState => {

               return {
                  chart: {
                     type: 'bar',
                  },
                  colors:[...chartData.colors],
                  plotOptions: {
                     bar: {
                        ...chartData.plotOption,
                        columnWidth: '65%',
                        
                     },
                  },
                  dataLabels: {
                     enabled: false
                  },
                  stroke: {
                     show: true,
                     width: 2,
                     colors: ['transparent']
                  },
                  xaxis: {
                     categories: [...chartData.labels],
                  },
                 
                  fill: {
                     opacity: 1
                  },
            
                  tooltip: {
                     y: {
                        formatter: function (val) {
                           return "CHF " + val
                        }
                     }
                  },

                  legend: {
                     horizontalAlign: 'left',
                     offsetX: 40
                  },
                  
                  yaxis: [...chartData.axis]
               }
            }) 

            break;

         case 'pie':
            
            setOptions(prevState => {

               return {
                  chart: {
                     width: 380,
                     type: 'pie',
                  },
                  labels:  [...chartData.labels],
                  responsive: [{
                     breakpoint: 480,
                     options: {
                        chart: {
                           width: 200
                        },
                        legend: {
                           position: 'bottom'
                        }
                     }
                  }]
               }
                  
            }) 
            
            break;

         case 'line':
          
            setOptions(prevState => {

               return {
                  chart: {
                     type: 'line',
                    
                  },
                  colors:[...chartData.colors],
                  dataLabels: {
                     enabled: false
                  },
                  
                  stroke: {
                     curve: 'straight'
                  },
                  markers: {
                     size: 3,
                  },
                  grid: {
                     row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                     },
                  },
                  xaxis: {
                     categories: [...chartData.labels],
                  },
                  legend: {
                     horizontalAlign: 'left',
                     offsetX: 40
                  },
                  
                  yaxis: [...chartData.axis]
                }
            }) 

            
            break;
         case 'area':
          
            setOptions(prevState => {

               return {
                  chart: {

                     type: 'area',
                  },
                  dataLabels: {
                     enabled: false
                  },
                  colors:[...chartData.colors],
                  stroke: {
                     width: 2,
                     curve: 'straight'
                  },
                  markers: {
                     size: 3,
                  },
                  xaxis: {
                     categories:[...chartData.labels]
                  }, 
                   
                  legend: {
                     horizontalAlign: 'left',
                     offsetX: 40
                  },
                  
                  yaxis: [...chartData.axis]
                 
               }
            }) 

            
            break;

         case 'combo':
          
            setOptions(prevState => {

               return {
                 
                  chart: {
                     type: 'line',
                  },
                  stroke: {
                     curve: 'straight',
                     width: getWidthLayout()
                  },
                  colors:[...chartData.colors],
                  plotOptions: {
                     bar: {
                        horizontal: false,
                        columnWidth: '100%',
                        
                     },
                  },
                  markers: {
                     size: 3,
                  },
                  dataLabels: {
                     enabled: false,
                  },
                  labels: [...chartData.labels],
                  xaxis: {
                     type: 'category'
                  },   
                  legend: {
                     horizontalAlign: 'left',
                     offsetX: 40
                  },
                  
                  yaxis: [...chartData.axis]
               }
            }) 

            
            break;
            
            default:
            break;
      }

   }, [chartData])

   useEffect(() => {
     
      setLockState(prevState => chartData.lockDrag)

   }, [chartData.lockDrag])

   return (
      /**
       * @string
       * chartData.name chartData.description
       */
      
      <Draggable isDragDisabled={isLock} key={chartData._id} draggableId={chartData._id} index={index}>
      {
         (provided, snapshot) => (
            <div
               ref={provided.innerRef}
               {...provided.draggableProps}
               {...provided.dragHandleProps}
            >  
             
               <Row className="mb-3">
                  <Col span={24}>

                     <Card 
                        className="shadow" 
                        title={
                           <div className="d-flex align-items-center"> 
                              <p style={{ color: 'darkslateblue', fontSize: '15px'}} className="m-0 mr-2"> 
                                 <strong>
                                    {chartData.name} {' '}  
                                 </strong> 
                              </p>
                              <p style={{fontSize: '15px'}} className="m-0"> 
                                 <strong>
                                    {
                                       isLock?
                                          <LockOutlined style={{ color: '#f44336' }} onClick={() => setLockState(false)} />:
                                          <UnlockOutlined style={{ color: '#32ea6a' }} onClick={() => setLockState(true)} />
                                    }
                                 </strong> 
                              </p>
                           </div>
                        } 
                        extra={
                           <ExtraOptions id={chartData._id} removeChart={removeChart} setTableVisibility={setTableVisibility} />
                        } 
                        bodyStyle={{padding: 0}} 
                        size="small" 
                     >

                        <Col span={24}>
                        
                           <Row>
                              <Col className="p-2" span={24}>
                                 <section>
                                    <Chart
                                       options={ options }
                                       series={ seriesData }
                                       type={chartData.type === 'combo'? 'line' : chartData.type}
                                       width="100%"
                                       height="277px"
                                    /> 
                                 </section>
                              </Col>
                           </Row>
                           {
                              showTable?
                                 <Row>
                                    <Col className="p-2" span={24}>
                                       <div>

                                          <Table columns={tableColumns} dataSource={tableData} size="small"  scroll={{ x: 1300 }}/>
                                       </div>
                                    </Col>
                                 </Row> : ''
                           }
                        </Col>
                     </Card>
                  </Col>
               </Row>
            </div>
         )
      }
      </Draggable>
   )
})

export default Board
