import React, { useReducer } from 'react'
import { CSVReducer } from '../reducers/CSVReducer'

const initialState = {

   files: [],
   desingerFile: []
}

export const CSVContext = React.createContext()

export const CSVProvider = props => {

   const [state, dispatch] = useReducer(CSVReducer, initialState)

   return (
      
      <CSVContext.Provider value={{ 
         ...state,
         dispatch
      }}>

         { props.children }
      </CSVContext.Provider>
   )
}
