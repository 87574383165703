import React from 'react'
import { Button } from 'antd'
import {
   CloseCircleOutlined,
   TableOutlined
 } from '@ant-design/icons'
const ExtraOptions = ({ setTableVisibility, removeChart, id }) => {
   return (
      <div>

         <Button 
            onClick={() => setTableVisibility(prevState => !prevState) } 
            type="primary" 
            size="small"
            shape="circle"
            className="text-center"
         > 
            <TableOutlined className="m-0" />
         </Button>

         <Button 
            onClick={() => removeChart(id) } 
            type="danger" 
            size="small"
            shape="circle"
            className="text-center ml-2"
         > 
            <CloseCircleOutlined className="m-0" />
         </Button>
       
      </div>
   )
}

export default ExtraOptions
