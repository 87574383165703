import React, { useState, useContext } from 'react'
import { Layout } from 'antd'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
/**
 * @import Views and Components
 */
import Main from './views/main/Main'
import Login from './views/login/Login'
import Loading from "./components/common/Loading"
import Header from './components/common/Header'
// import Footer from './components/common/Footer'
import SessionRouter from './SessionRouter'
// import './App.css'
import bg from './brand/bg.png'

// Contexts
import { AuthContext } from "./contexts/AuthContext"
// Providers
import { CSVProvider } from './contexts/CSVContext' 
import { DashboardProvider } from './contexts/DashboardContext' 

function App() {
   
   const Auth = useContext(AuthContext)

   const [sidebarCollapsed, setSBCollapsed] = useState(false)
   const { Content } = Layout 


   const getMainComponent = () => {
      return (
         <CSVProvider>
            <DashboardProvider>
               <Main />
            </DashboardProvider>
         </CSVProvider>
      )
   }

   const getLoginComponent = () => {
      return (
         <Login />
      )
   }

   return (
      <>
         {
            Auth.isLoading? <Loading /> : 
            
            <Router>
              
               <main id="main">
                  {
                     Auth.isOnline? 
                     <Header user={Auth.user} sidebarCollapsed={sidebarCollapsed} setSBCollapsed={setSBCollapsed} /> : ''
                  }
                  <Layout style={!Auth.isOnline? { 
                     backgroundImage: `url(${bg})`,
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center center',
                     height: '100vh'
                   }: {}}>
                   
                    
                     <Layout style={{background: 'none'}}>
                  
                        <Content>
                           <Switch>
                              
                              <SessionRouter exact path="/" >
                                 { getMainComponent() }
                              </SessionRouter>

                              <SessionRouter exact path="/login">
                                 { getLoginComponent() }
                              </SessionRouter>

                           </Switch>
                        </Content>
                     </Layout>
                    
                  </Layout>
               </main>
                     
            </Router>
         }  
      </>
      
   );
}

export default App
